import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import { Layout } from '../components'

import { container, padding, content, button, pageTitle } from '../styles/global';
import { media } from '../styles/utils';

const OTButton = styled.a`
	background-color: #da3743;
	border: 1px solid #da3743;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	display: block;
	font-weight: bold;
	padding: 14px 0 15px;
	text-align: center;
	text-decoration: none;
	width: 100%;
	max-width: 250px;
`

const VenueReserve = ({ pageContext, ...props }) => {
	const data = JSON.parse(pageContext.acf_json)

	// const [isWidgetLoaded, setIsWidgetLoaded] = useState(false)
	// var widget = typeof SevenroomsWidget !== 'undefined' && isWidgetLoaded

	// useEffect(() => {
	// 	var script = document.createElement("script");
	// 	script.id = 'sevenroomsscript'
	// 	script.onload = () => { setIsWidgetLoaded(true) }
	// 	script.src = "https://www.sevenrooms.com/widget/embed.js"
	// 	document.body.appendChild(script);
	// }, [])

	// useEffect(() => {
	// 	if (widget) {
	// 		SevenroomsWidget.init({
	// 			venueId: "pepesitalianliquor",
	// 			triggerId: "sr-res-root",
	// 			type: "reservations",
	// 			styleButton: true,
	// 			clientToken: "",
	// 		})
	// 	}
	// }, [widget])

	return (
		<Layout
			meta={data && data.seo}
			menu={pageContext.menu}
		>
			<Container>
				<Heading>{pageContext.sectionTitle}</Heading>

				<Content>
					<Description
						dangerouslySetInnerHTML={{ __html: data.description }}
					/>

					{/* {widget ? (
						<div id="sr-res-root" className="sr-md sr-light sr-#451f1f">Reservations</div>
					) : (
						<div>Loading...</div>
					)} */}

					<OTButton href="https://www.sevenrooms.com/reservations/pepesitalianliquor">Reservations</OTButton>
				</Content>
			</Container>
		</Layout>
	)
}


// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Button = styled.a`
	${button}
`

// Layout

const Container = styled.div`
	${container}
	${padding}
    flex-direction: column;
	align-items: center;
	flex: 1;
	
	> ${Heading} {
		${pageTitle}
	}
`

const Content = styled.div`
	${content}

	${Description} {
		max-width: 440px;
		font-weight: bold;
		font-size: 22px;
		text-align: center;
		line-height: 24px;
		text-transform: uppercase;
	}
	
	${Button} {
		margin-top: 68px;
	}

	${media.phone`
		${Description} {
			max-width: 280px;
			p, a {
				font-size: 16px;
				line-height: 18px;
			}
		}

		${Button} {
			margin-top: 32px;
		}
	`}
`

export default VenueReserve

